.myCustomList::-webkit-scrollbar {
	width: 8px;
	background-color: rgb(254, 249, 249);
}

.myCustomList::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: lightgrey;
}
